<template>
  <div>
    <BaseTable
      v-model="selectedRecords"
      :headers="autoMergesToReviewTableHeaders"
      :records="autoMergesToReview"
      :per-page="autoMergesToReviewPerPage"
      :total-items="autoMergesToReviewTotalItems"
      :is-loading="isLoading"
      :title="title"
      tooltip="This list contains duplicate sets requiring review. Click anywhere within a row to review."
      show-select
      disable-dragging
      disable-resizing
      @open="openAutoMergeDialog"
      @fetchData="fetchData"
    >
      <template #search="{ search, updateSearch }">
        <AdvancedDashboardFilter
          :search="search"
          :update-search="updateSearch"
          max-width="550px"
        />
      </template>
      <template #action>
        <v-slide-x-reverse-transition>
          <v-btn
            v-show="isExcludeFromBulkMergeVisible"
            color="primary"
            outlined
            height="40"
            class="mb-1"
            @click="showExcludeDialog = true"
          >
            Exclude from Bulk Merge
          </v-btn>
        </v-slide-x-reverse-transition>
        <v-btn
          v-show="isBulkMergeSelectedVisible"
          color="primary"
          height="40"
          class="ml-1 mb-1"
          @click="openConfirmDialog(false)"
        >
          Bulk Merge Selected
        </v-btn>
        <v-btn
          v-show="isBulkMergeEnabled"
          color="primary"
          height="40"
          class="ml-1 mb-1"
          @click="openConfirmDialog(true)"
        >
          Bulk Merge all
        </v-btn>
      </template>
    </BaseTable>

    <ApDialog
      v-model="showConfirmDialog"
      title="Confirm Bulk Merge"
      title-color-class="ap-green--text"
      max-width="440"
      is-content-centered
      :content="[
        'The selected duplicate sets will be merged, please confirm.',
        'This action can only be undone by following the manual process of undoing merges from the CRM.',
      ]"
      :actions="dialogActions"
      @cancel="showConfirmDialog = false"
      @confirm="autoMerge"
    />

    <ApDialog
      v-model="showExcludeDialog"
      title="Stop Bulk Merge"
      title-color-class="ap-green--text"
      max-width="440"
      is-content-centered
      :content="[
        'Please confirm that you want to stop the selected sets from merging.',
        'These sets will be assigned to (user) according the Assignment Rules.',
      ]"
      :actions="dialogActions"
      @cancel="showExcludeDialog = false"
      @confirm="excludeAutoMerge"
    />

    <template v-if="isNewDupegridEnabled">
      <DupeGridDialog
        v-if="showDupesGridDialog"
        title="Duplicate Set"
        :records="dialogDupeSets"
        @close="showDupesGridDialog = false"
      />
    </template>

    <template v-else>
      <DupesGridDuplicateSetsDialog
        v-model="showDupesGridDialog"
        :dupe-sets="dialogDupeSets"
      />
    </template>
  </div>
</template>

<script>
import {
  updateAutoMergeState,
  updateAutoMergesStatesByIds,
  updateAutoMergesStatesBySearchField,
} from '@/api/dupe-sets/dupe-sets'
import { defaultTo } from 'lodash-es'
import { mapState, mapActions, mapGetters } from 'vuex'
import BaseTable from './BaseTable'
import DupesGridDuplicateSetsDialog from '@/components/dupesgrid/DupesGridDuplicateSetsDialog'
import AdvancedDashboardFilter from '@/components/shared/AdvancedDashboardFilter'
import ApDialog from '@/components/common/ApDialog'
import DupeGridDialog from '../dupegrid/DupeGridDialog'

export default {
  components: {
    BaseTable,
    DupesGridDuplicateSetsDialog,
    AdvancedDashboardFilter,
    ApDialog,
    DupeGridDialog,
  },
  props: {
    title: {
      type: String,
      default: 'Auto-merges to be reviewed',
    },
  },
  data() {
    return {
      isLoading: false,
      selectedRecords: [],
      showDupesGridDialog: false,
      dialogDupeSets: [],
      isLoadingAction: false,
      isMergeAll: false,
      showConfirmDialog: false,
      showExcludeDialog: false,
      recordOptions: {},
      paginationItemsPerPage: 10,
      isNewDupegridEnabled:
        process.env.VUE_APP_NEW_DUPEGRID_ENABLED === 'true' ||
        localStorage.getItem('is_new_dupegrid_enabled') === 'true',
    }
  },
  computed: {
    ...mapState('dashboard', [
      'autoMergesToReview',
      'autoMergesToReviewPerPage',
      'autoMergesToReviewTotalItems',
      'isBulkMergeEnabled',
    ]),
    ...mapGetters('dashboard', ['autoMergesToReviewTableHeaders']),
    isExcludeFromBulkMergeVisible() {
      return this.selectedRecords.length > 0
    },
    isBulkMergeSelectedVisible() {
      return (
        this.autoMergesToReview.length > 0 && this.selectedRecords.length > 0
      )
    },
    dialogActions() {
      return [
        {
          event: 'cancel',
          label: 'No, take me back',
          disabled: this.isLoadingAction,
          outlined: true,
          color: 'primary',
        },
        {
          event: 'confirm',
          label: 'Confirm',
          disabled: this.isLoadingAction,
          color: 'primary',
          leftIcon: '$mdi-check',
        },
      ]
    },
  },
  watch: {
    showDupesGridDialog(val) {
      if (!val) this.fetchData()
    },
  },
  methods: {
    ...mapActions('dashboard', ['getAutoMergesToReview']),
    openAutoMergeDialog(item) {
      if (item) {
        this.dialogDupeSets = [item]
      } else if (this.selectedRecords.length > 0) {
        this.dialogDupeSets = this.selectedRecords
      } else {
        this.dialogDupeSets = this.autoMergesToReview
      }

      this.showDupesGridDialog = true
    },
    openConfirmDialog(isMergeAll) {
      this.showConfirmDialog = true
      this.isMergeAll = isMergeAll
    },
    async fetchData(options) {
      this.isLoading = true
      if (options) {
        this.recordOptions = options
      }

      await this.getAutoMergesToReview({
        perPage: this.paginationItemsPerPage,
        ...this.recordOptions,
      })

      this.isLoading = false
    },
    async autoMerge() {
      this.isLoadingAction = true

      if (this.isMergeAll) {
        await updateAutoMergesStatesBySearchField(
          defaultTo(this.recordOptions.search, ''),
          this.recordOptions.search_field
        )
      } else {
        const ids = this.selectedRecords.map((n) => n.id)

        await updateAutoMergesStatesByIds(ids)
      }

      this.isLoadingAction = false
      this.showConfirmDialog = false
      this.fetchData()
    },
    async excludeAutoMerge() {
      this.isLoadingAction = true
      const promises = []

      this.selectedRecords.forEach((record) => {
        promises.push(updateAutoMergeState(record.id, 'denied_approval'))
      })

      await Promise.all(promises)

      this.isLoadingAction = false
      this.showExcludeDialog = false
      this.fetchData()
    },
  },
}
</script>
