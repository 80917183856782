var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{attrs:{"headers":_vm.autoMergesToReviewTableHeaders,"records":_vm.autoMergesToReview,"per-page":_vm.autoMergesToReviewPerPage,"total-items":_vm.autoMergesToReviewTotalItems,"is-loading":_vm.isLoading,"title":_vm.title,"tooltip":"This list contains duplicate sets requiring review. Click anywhere within a row to review.","show-select":"","disable-dragging":"","disable-resizing":""},on:{"open":_vm.openAutoMergeDialog,"fetchData":_vm.fetchData},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var search = ref.search;
var updateSearch = ref.updateSearch;
return [_c('AdvancedDashboardFilter',{attrs:{"search":search,"update-search":updateSearch,"max-width":"550px"}})]}},{key:"action",fn:function(){return [_c('v-slide-x-reverse-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExcludeFromBulkMergeVisible),expression:"isExcludeFromBulkMergeVisible"}],staticClass:"mb-1",attrs:{"color":"primary","outlined":"","height":"40"},on:{"click":function($event){_vm.showExcludeDialog = true}}},[_vm._v(" Exclude from Bulk Merge ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBulkMergeSelectedVisible),expression:"isBulkMergeSelectedVisible"}],staticClass:"ml-1 mb-1",attrs:{"color":"primary","height":"40"},on:{"click":function($event){return _vm.openConfirmDialog(false)}}},[_vm._v(" Bulk Merge Selected ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBulkMergeEnabled),expression:"isBulkMergeEnabled"}],staticClass:"ml-1 mb-1",attrs:{"color":"primary","height":"40"},on:{"click":function($event){return _vm.openConfirmDialog(true)}}},[_vm._v(" Bulk Merge all ")])]},proxy:true}]),model:{value:(_vm.selectedRecords),callback:function ($$v) {_vm.selectedRecords=$$v},expression:"selectedRecords"}}),_c('ApDialog',{attrs:{"title":"Confirm Bulk Merge","title-color-class":"ap-green--text","max-width":"440","is-content-centered":"","content":[
      'The selected duplicate sets will be merged, please confirm.',
      'This action can only be undone by following the manual process of undoing merges from the CRM.' ],"actions":_vm.dialogActions},on:{"cancel":function($event){_vm.showConfirmDialog = false},"confirm":_vm.autoMerge},model:{value:(_vm.showConfirmDialog),callback:function ($$v) {_vm.showConfirmDialog=$$v},expression:"showConfirmDialog"}}),_c('ApDialog',{attrs:{"title":"Stop Bulk Merge","title-color-class":"ap-green--text","max-width":"440","is-content-centered":"","content":[
      'Please confirm that you want to stop the selected sets from merging.',
      'These sets will be assigned to (user) according the Assignment Rules.' ],"actions":_vm.dialogActions},on:{"cancel":function($event){_vm.showExcludeDialog = false},"confirm":_vm.excludeAutoMerge},model:{value:(_vm.showExcludeDialog),callback:function ($$v) {_vm.showExcludeDialog=$$v},expression:"showExcludeDialog"}}),(_vm.isNewDupegridEnabled)?[(_vm.showDupesGridDialog)?_c('DupeGridDialog',{attrs:{"title":"Duplicate Set","records":_vm.dialogDupeSets},on:{"close":function($event){_vm.showDupesGridDialog = false}}}):_vm._e()]:[_c('DupesGridDuplicateSetsDialog',{attrs:{"dupe-sets":_vm.dialogDupeSets},model:{value:(_vm.showDupesGridDialog),callback:function ($$v) {_vm.showDupesGridDialog=$$v},expression:"showDupesGridDialog"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }