<template>
  <DupesGridDialog
    v-if="showDialog"
    v-model="currentDupeSet"
    title="Duplicate Set"
    :records="dupeSets"
    :footer-details="currentDupeSetDetails"
    @close="showDialog = false"
  />
</template>

<script>
import DupesGridDialog from './DupesGridDialog'

export default {
  components: {
    DupesGridDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dupeSets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDialog: false,
      currentDupeSet: {},
    }
  },
  computed: {
    currentDupeSetDetails() {
      const items = []

      if (this.currentDupeSet.number) {
        items.push({
          label: 'Set ID:',
          value: this.currentDupeSet.number,
        })
      }

      if (this.currentDupeSet.ownerName) {
        items.push({
          label: 'Owner:',
          value: this.currentDupeSet.ownerName,
        })
      }

      if (this.currentDupeSet.createdAtDate) {
        items.push({
          label: 'Created:',
          value: this.currentDupeSet.createdAtDate,
        })
      }

      if (this.currentDupeSet.modifiedAtDate) {
        items.push({
          label: 'Modified:',
          value: this.currentDupeSet.modifiedAtDate,
        })
      }

      return items
    },
  },
  watch: {
    showDialog(value) {
      this.$emit('input', value)
    },
    value(value) {
      this.showDialog = value
    },
  },
}
</script>
