<template>
  <TableAutoMerge />
</template>

<script>
import TableAutoMerge from '@/components/tables/TableAutoMerge'

export default {
  components: {
    TableAutoMerge,
  },
}
</script>
